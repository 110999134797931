//获取用户信息
import axiosReq from "@/utils/axios-req";
export const userInfoReq = () => {
    return new Promise((resolve) => {
        const reqConfig = {
            url: "/api/v1/user/getInfo",
            method: "get",
        };
        axiosReq(reqConfig).then(({ data }) => {
            resolve(data);
        });
    });
};

//登录
export const loginReq = (subForm) => {
    return axiosReq({
        url: "/api/v1/login",
        params: subForm,
        method: "post",
    });
};

//退出登录
export const loginOutReq = () => {
    return axiosReq({
        url: "/api/v1/logout",
        method: "post",
    });
};

//signup
export const signUpReq = (subForm) => {
    return axiosReq({
        url: "/api/v1/signup",
        params: subForm,
        method: "post",
    });
};
